<template>
  <div class="fluid" id="employeeList">
    <div>
      <div class="employee-list-div">
        <v-card class="px-3 py-2">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="
                position: relative;
                padding: 0 20px;
                text-align: center;
                margin-top: 20px;
              "
            >
              <h6 class="indigo--text" style="font-weight: bold; margin: auto">
                DATA KARYAWAN
              </h6>
            </v-col>
            <v-col cols="12">
              <div>
                <v-toolbar class="employee-list-toolbar-1" flat>
                  <div style="width: 100%" class="d-flex justify-space-between">
                    <div>
                      <router-link to="/hr/employee/registration_form">
                        <v-btn
                          outlined
                          elevation="1"
                          color="indigo"
                          class="indigo--text font-weight-bold"
                          style="font-size: 12px; margin-left: 5px"
                        >
                          Formulir Registrasi
                        </v-btn>
                      </router-link>
                    </div>
                    <div class="d-flex">
                      <div
                        v-if="
                          getUserProfile.level.find(
                            ({ id }) => id === '1' || id === '39' || id === '42'
                          ) !== undefined
                        "
                        style="
                          margin-left: 10px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <v-btn
                          rounded
                          outlined
                          small
                          elevation="1"
                          color="indigo"
                          class="indigo--text"
                          style="font-size: 12px"
                          @click="exportData"
                        >
                          Export
                        </v-btn>
                      </div>
                      <!-- <div
                        v-if="
                          getUserProfile.level.find(
                            ({ id }) => id === '1' || id === '39'
                          ) !== undefined
                        "
                        style="
                          margin-left: 10px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <v-btn
                          rounded
                          small
                          elevation="1"
                          color="indigo"
                          class="white--text"
                          style="font-size: 12px"
                          @click.stop="importData"
                        >
                          Import
                          <v-icon size="15" style="margin-left: 5px">
                            mdi-file-import-outline
                          </v-icon>
                        </v-btn>
                      </div> -->
                      <div
                        class="employee-list-toolbar-div"
                        style="margin-left: 20px; width: 300px"
                      >
                        <v-text-field
                          v-model="paramAPI.keyword"
                          label="Cari disini"
                          type="search"
                          outlined
                          dense
                          append-icon="mdi-magnify"
                          style="position: relative; top: 15px"
                          @keyup.enter="searchEnter"
                          :disabled="loading"
                        ></v-text-field>
                      </div>
                    </div>
                  </div>
                </v-toolbar>
                <v-toolbar class="employee-list-toolbar-1" flat>
                  <div
                    v-if="
                      getUserProfile.level.find(
                        ({ id }) => id === '1' || id === '39'
                      ) !== undefined
                    "
                  >
                    <div style="width: 140px">
                      <v-select
                        :disabled="selectedData.length === 0"
                        label="Action"
                        style="
                          position: relative;
                          top: 15px;
                          margin-left: 5px;
                          font-size: 12px;
                        "
                        v-model="actionValue"
                        :items="[
                          { id: 0, name: '' },
                          { id: 1, name: 'Delete' },
                          { id: 2, name: 'Inactivate' },
                          { id: 3, name: 'Reset Device' },
                          { id: 4, name: 'Reset Password' }
                        ]"
                        item-text="name"
                        item-value="id"
                        outlined
                        return-id
                        dense
                        @change="action"
                      ></v-select>
                    </div>
                  </div>
                  <div style="width: 150px; margin-left: 10px">
                    <v-select
                      @change="companyWatcher"
                      v-model="paramAPI.company_id"
                      :items="dropdown.company"
                      style="
                        position: relative;
                        top: 15px;
                        margin-left: 5px;
                        font-size: 12px;
                      "
                      label="Company"
                      item-text="name"
                      item-value="id"
                      outlined
                      return-id
                      dense
                      :disabled="isDisableCompanyDropdown"
                    ></v-select>
                  </div>
                  <div style="width: 140px; margin-left: 20px">
                    <v-select
                      @change="departmentWatcher"
                      label="Dept/ Section"
                      v-model="paramAPI.department_id"
                      :items="dropdown.department"
                      style="position: relative; top: 15px; font-size: 12px"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                      clearable
                    ></v-select>
                  </div>
                  <div style="width: 140px; margin-left: 20px">
                    <v-select
                      @change="levelWatcher"
                      label="Jabatan"
                      v-model="paramAPI.level_id"
                      :items="dropdown.level"
                      style="position: relative; top: 15px; font-size: 12px"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                      clearable
                    ></v-select>
                  </div>
                  <!-- <div style="width: 140px; margin-left:20px;">
                    <v-select
                      label="Section"
                      v-model="paramAPI.section_id"
                      :items="dropdown.section"
                      style="
                        position: relative;
                        top: 15px;
                        font-size: 12px;
                      "
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                    ></v-select>
                  </div> -->
                  <!-- <div style="width: 140px; margin-left:20px;">
                    <v-select
                      label="Status Karyawan"
                      :items="dropdown.employee_status"
                      style="
                        position: relative;
                        top: 15px;
                        font-size: 12px;
                      "
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                      v-model="paramAPI.employee_status_id"
                    ></v-select>
                  </div> -->
                  <!-- <div style="width: 140px; margin-left:20px;">
                    <v-select
                      label="Status Keaktifan"
                      :items="[
                        {
                          id: 1,
                          name: 'Active'
                        },
                        {
                          id: 2,
                          name: 'Unactive'
                        }
                      ]"
                      style="
                        position: relative;
                        top: 15px;
                        font-size: 12px;
                      "
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                    ></v-select>
                  </div> -->
                </v-toolbar>
              </div>
              <div class="employee-list-toolbar-2">
                <v-expansion-panels flat accordion>
                  <v-expansion-panel v-for="(item, i) in 1" :key="i" flat>
                    <v-expansion-panel-header
                      class="d-flex align-items-start"
                      style="padding: 0 0 0 15px"
                    >
                      <p style="color: rgba(0, 0, 0, 0.4)">
                        Tampilkan Filter & Pencarian
                      </p>
                      <template v-slot:actions>
                        <v-icon color="primary"> $expand </v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content id="expansionPanelContent">
                      <v-select
                        @change="companyWatcher"
                        v-model="paramAPI.company_id"
                        :items="dropdown.company"
                        style="height: 50px"
                        label="Company"
                        item-text="name"
                        item-value="id"
                        outlined
                        return-id
                        dense
                        :disabled="isDisableCompanyDropdown"
                      ></v-select>
                      <v-select
                        @change="departmentWatcher"
                        label="Dept/ Section"
                        v-model="paramAPI.department_id"
                        :items="dropdown.department"
                        style="height: 50px"
                        item-text="name"
                        item-value="id"
                        outlined
                        return-id
                        dense
                        clearable
                      ></v-select>
                      <v-select
                        @change="levelWatcher"
                        label="Jabatan"
                        v-model="paramAPI.level_id"
                        :items="dropdown.level"
                        style="height: 50px"
                        item-text="name"
                        item-value="id"
                        outlined
                        return-id
                        dense
                        clearable
                      ></v-select>
                      <v-text-field
                        v-model="paramAPI.keyword"
                        label="Cari disini"
                        type="search"
                        outlined
                        dense
                        append-icon="mdi-magnify"
                        @keyup.enter="searchEnter"
                        :disabled="loading"
                      ></v-text-field>

                      <div
                        v-if="
                          getUserProfile.level.find(({ id }) => id === '1') !==
                          undefined
                        "
                        flat
                        style="
                          border-radius: 10px 10px 0 0;
                          border-bottom: 1px solid #e0e0e0;
                          width: 100%;
                          margin-bottom: 10px;
                        "
                      >
                        <v-btn
                          outlined
                          rounded
                          elevation="1"
                          color="indigo"
                          class="white--text"
                          style="
                            font-size: 12px;
                            position: relative;
                            width: 100%;
                          "
                          @click="exportData"
                          >Export</v-btn
                        >
                      </div>
                      <!-- <div
                        v-if="
                          getUserProfile.level.find(({ id }) => id === '1') !==
                          undefined
                        "
                        flat
                        style="
                          border-radius: 10px 10px 0 0;
                          border-bottom: 1px solid #e0e0e0;
                          width: 100%;
                          margin-bottom: 10px;
                        "
                      >
                        <v-btn
                          rounded
                          elevation="1"
                          color="indigo"
                          class="white--text"
                          style="
                            font-size: 12px;
                            position: relative;
                            width: 100%;
                          "
                          @click.stop="importData"
                        >
                          Import
                          <v-icon size="15" style="margin-left: 5px">
                            mdi-file-import-outline
                          </v-icon>
                        </v-btn>
                      </div> -->
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <div
                  v-if="
                    getUserProfile.level.find(
                      ({ id }) => id === '1' || id === '39'
                    ) !== undefined
                  "
                  style="padding: 0 10px"
                >
                  <v-select
                    :disabled="selectedData.length === 0"
                    label="Action"
                    style="
                      position: relative;
                      top: 15px;
                      margin-left: 5px;
                      font-size: 12px;
                    "
                    v-model="actionValue"
                    :items="[
                      { id: 0, name: '' },
                      { id: 1, name: 'Delete' },
                      { id: 2, name: 'Unactivated' },
                      { id: 3, name: 'Reset Device' },
                      { id: 4, name: 'Reset Password' }
                    ]"
                    item-text="name"
                    item-value="id"
                    outlined
                    return-id
                    dense
                    @change="action"
                  ></v-select>
                </div>
              </div>
            </v-col>

            <v-col cols="12">
              <v-data-table
                mobile-breakpoint="0"
                fixed-header
                height="60vh"
                v-model="selectedData"
                :headers="headers"
                style="cursor: pointer"
                :items="result"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
                :show-select="
                  this.getUserProfile.level.find(
                    ({ id }) => id === '1' || id === '39'
                  ) !== undefined
                "
                :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [1, 3, 5, 10, 15, 100, 1000]
                }"
                :page="paramAPI.page"
                :server-items-length="totalData"
                :items-per-page="paramAPI.itemsPerPage"
                @update:page="updatePage"
                @update:items-per-page="updateItemPerPage"
              >
                <template v-slot:[`item.level`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      width: 100px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ item.level !== null ? item.level.name : '-' }}
                  </div>
                </template>
                <template v-slot:[`item.nik`]="{ item }">
                  <div
                    style="
                      width: 110px;
                      font-size: 12px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ item.nik }}
                  </div>
                </template>
                <template v-slot:[`item.email`]="{ item }">
                  <div
                    style="
                      width: 210px;
                      font-size: 12px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ item.email }}
                  </div>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <div
                    style="
                      width: 160px;
                      font-size: 12px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ item.name }}
                  </div>
                </template>
                <template v-slot:[`item.department`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      width: 120px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ item.department !== null ? item.department.name : '-' }}
                    -
                    {{
                      item.department !== null
                        ? item.department.section !== null
                          ? item.department.section.name
                          : '-'
                        : '-'
                    }}
                  </div>
                </template>
                <template v-slot:[`item.company_name`]="{ item }">
                  <div
                    style="
                      width: 160px;
                      font-size: 12px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ item.company_name }}
                  </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      width: 70px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ statusEmployee(item.status) }}
                  </div>
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      width: 100px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ convertDate(item.created_at) }}
                  </div>
                </template>
                <template v-slot:[`item.updated_at`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      width: 100px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ convertDate(item.updated_at) }}
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
    <div
      v-if="loader"
      class="d-flex justify-center align-center"
      style="
        position: absolute;
        top: 0;
        z-index: 3;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
      "
    >
      <v-progress-circular
        :size="100"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <EmployeeImport @loadPage="getDataFromApi" />
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
import * as XLSX from 'xlsx/xlsx.mjs'
export default {
  name: 'division',
  components: {
    EmployeeImport: () => import('./components/Import.vue')
  },
  data: () => ({
    dialog: false,
    hrsApi: buildType.apiURL('hrs'),
    oAuthApi: buildType.apiURL('oauth'),
    build: process.env.VUE_APP_BUILD_TYPE,
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      status: '',
      employee_status_id: null,
      sortBy: 'name',
      sortType: 'asc',
      company_id: null,
      department_id: null,
      level_id: null,
      section_id: null,
      itemsPerPage: 10
    },
    totalData: 0,

    options: {},
    actionValue: 0,
    headers: [
      {
        text: 'NIK',
        value: 'nik',
        align: 'left',
        sortable: true
      },
      {
        text: 'Nama',
        value: 'name',
        align: 'left',
        sortable: true
      },
      {
        text: 'Email',
        value: 'email',
        align: 'left',
        sortable: true
      },
      {
        text: 'Perusahaan',
        value: 'company_name',
        align: 'left',
        sortable: true
      },
      {
        text: 'Department',
        value: 'department',
        align: 'left',
        sortable: true
      },
      // {
      //   text: '',
      //   value: 'section',
      //   align: 'left',
      //   sortable: true
      // },
      {
        text: 'Jabatan',
        value: 'level',
        align: 'left',
        sortable: true
      },
      {
        text: 'Status',
        value: 'status',
        align: 'left',
        sortable: true
      },
      {
        text: 'Dibuat pada',
        value: 'created_at',
        align: 'left',
        sortable: true
      },
      {
        text: 'Diupdate pada',
        value: 'updated_at',
        align: 'left',
        sortable: true
      }
    ],
    result: [],
    dropdown: {
      employee_status: [],
      section: [],
      company: [],
      department: [],
      level: []
    },
    selectedData: [],
    loading: true,
    nameRules: [],
    loader: false,
    isDisableCompanyDropdown: false
  }),

  async mounted() {
    // this.paramAPI.company_id = Number(
    //   this.getUserProfile.employee.company.plant_id
    // )
    let xFlag = false
    const level = this.getUserProfile.level.find(
      ({ id }) => id === '1' || id === '39' || id === '42'
    )

    if (level !== undefined) {
      if (level.user_user_level !== null) {
        if (
          level.user_user_level.allowed_company != null &&
          level.user_user_level.allowed_company.length != 0
        ) {
          if (
            level.user_user_level.allowed_company.find(
              ({ id }) => id == this.getUserProfile.employee.company.plant_id
            ) === undefined
          ) {
            xFlag = true
            this.paramAPI.company_id = Number(
              level.user_user_level.allowed_company[0].id
            )
          }
        }
      }
    }

    if (!xFlag) {
      this.paramAPI.company_id = Number(
        this.getUserProfile.employee.company.plant_id
      )
    }

    if (this.getEmployeePageQuery !== null) {
      this.paramAPI = this.getEmployeePageQuery
      // this.paramAPI.keyword = this.getEmployeePageQuery.keyword
      // this.paramAPI.company_id = this.getEmployeePageQuery.company_id
      // this.paramAPI.sortBy = this.getEmployeePageQuery.order_by
      // this.paramAPI.sortType = this.getEmployeePageQuery.order_type
      // this.paramAPI.offset = this.getEmployeePageQuery.offset
      // this.paramAPI.limit = this.getEmployeePageQuery.limit
      // this.paramAPI.department_id = this.getEmployeePageQuery.department_id
      // this.paramAPI.level_id = this.getEmployeePageQuery.level_id
      // this.paramAPI.page = this.getEmployeePageQuery.page
      // this.options.page = this.getEmployeePageQuery.page
    }
    setTimeout(async () => {
      await this.getDataFromApi()
      await this.initDropdown()
      this.setAction()
    }, 200)
  },
  watch: {},
  computed: {
    // 'getDropdownPlant',
    ...mapGetters([
      'getUserProfile',
      'getEmployeePageQuery'
      // 'getDropdownPlant'
    ])
  },
  methods: {
    ...mapActions(['']),
    ...mapMutations(['setEmployeePageQuery']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel, input) {
      const settings = {
        position: 'center',
        icon: pModalType,
        html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
        showConfirmButton: true,
        showCancelButton: pBtnCancel,
        cancelButtonText: 'No'
      }

      if (input !== undefined) {
        if (input) {
          Object.assign(settings, {
            inputPlaceholder: 'Tuliskan keterangan',
            input: 'text'
            // inputAttributes: {
            //   autocapitalize: 'false'
            // }
          })
        }
      }

      return new Promise((resolve) => {
        this.$swal.fire(settings).then((r) => {
          resolve(r)
        })
      })
    },
    initDropdown() {
      this.getDropdownCompany()
      // this.dropdownPlant()
      this.dropdownDepartment(this.paramAPI.company_id)
      this.dropdownLevel()
      this.dropdownEmployeeStatus()
    },
    close() {
      this.form = {
        act: 'add',
        id: '',
        name: ''
      }
      this.dialog = false
    },
    action(event) {
      switch (event) {
        case 1:
          this.delete()
          break
        case 2:
          if (this.selectedData.length < 2) {
            this.nonActiveEmployee()
          } else {
            this.showMsgDialog('warning', 'Pilih salah satu untuk edit', false)
            setTimeout(() => {
              this.selectedData = []
              this.actionValue = 0
            }, 200)
          }
          break
        case 3:
          this.resetDevice()
          break
        case 4:
          if (this.selectedData.length < 2) {
            this.resetPassword()
          } else {
            this.showMsgDialog('warning', 'Pilih salah satu untuk edit', false)
            setTimeout(() => {
              this.selectedData = []
              this.actionValue = 0
            }, 200)
          }
          break
      }
    },
    async searchEnter() {
      await this.getDataFromApi()
    },
    setAction() {
      this.actionValue = 0
      this.selectedData = []
    },

    async getDataFromApi() {
      this.loading = true
      this.selectedData = []
      await this.initDataTable()
        .then((data) => {
          if (data.status_code === '00') {
            this.totalData = data.total_record
            return (this.result = data.data)
          }
          return this.showMsgDialog('warning', data.status_msg, 'false')
        })
        .catch((err) => {
          console.log(err)
          return (this.result = [])
        })
      this.loading = false
    },
    initDataTable() {
      let url = `${this.hrsApi}employee/list?keyword=${
        this.paramAPI.keyword
      }&company_id=${this.paramAPI.company_id}&order_by=${
        this.paramAPI.sortBy
      }&order_type=${this.paramAPI.sortType}&offset=${
        this.paramAPI.offset * this.paramAPI.limit
      }&limit=${this.paramAPI.limit}`

      if (this.paramAPI.department_id !== null) {
        url = url + `&department_id=${this.paramAPI.department_id}`
      }
      if (this.paramAPI.level_id !== null) {
        url = url + `&level_id=${this.paramAPI.level_id}`
      }
      this.setEmployeePageQuery(this.paramAPI)
      // this.setEmployeePageQuery({
      //   keyword: this.paramAPI.keyword,
      //   company_id: this.paramAPI.company_id,
      //   order_by: this.paramAPI.sortBy,
      //   order_type: this.paramAPI.sortType,
      //   offset: this.paramAPI.offset,
      //   limit: this.paramAPI.limit,
      //   department_id: this.paramAPI.department_id,
      //   level_id: this.paramAPI.level_id,
      //   page: this.paramAPI.page
      // })
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    rowClick(pItem) {
      if (
        this.getUserProfile.level.find(({ id }) => id === '39') !== undefined
      ) {
        this.$router.push(`/hr/employee/detail/${pItem.id}`)
      }
    },
    delete() {
      this.showMsgDialog(
        'question',
        `${this.selectedData.length} data akan dihapus secara permanent, Anda yakin ?`,
        true
      ).then((res) => {
        if (res.isConfirmed) {
          this.startDelete()
        } else {
          this.actionValue = 0
        }
      })
    },
    async startDelete() {
      this.loader = true
      const arrSuccess = []
      const arrFail = []
      let i = 0
      while (
        (await new Promise((resolve, reject) => {
          axios
            .delete(`${this.hrsApi}employee/delete/${this.selectedData[i].id}`)
            .then((res) => {
              if (res.data.status_code === '00') {
                arrSuccess.push({
                  index: i,
                  msg: res.data.status_msg
                })
              } else {
                arrFail.push({
                  index: i,
                  msg: res.data.status_msg
                })
              }
              resolve(i++)
            })
            .catch((err) => {
              console.log(err)
              arrFail.push({
                index: i,
                msg: 'error'
              })
              reject(new Error(i++))
            })
        })) <
        this.selectedData.length - 1
      ) {
        console.log('cek ' + i)
      }

      this.loader = false
      this.actionValue = 0
      this.showMsgDialog(
        'success',
        `${arrSuccess.length} data berhasil terhapus <br/> ${arrFail.length} data gagal terhapus pada index [${arrFail}]`,
        false
      )
      return this.getDataFromApi()
    },

    async nonActiveEmployee() {
      this.showMsgDialog(
        'question',
        'Anda yakin ingin meng-nonaktifkan data karyawan ini',
        true,
        true
      ).then(async (res) => {
        if (res.isConfirmed) {
          this.loading = true
          await axios
            .post(`${this.hrsApi}employee/non_active`, {
              id: this.selectedData[0].id,
              note: res.value
            })
            .then(async (res) => {
              if (res.data.status_code === '00') {
                await this.getDataFromApi()
                return this.showMsgDialog(
                  'success',
                  res.data.status_msg,
                  'false'
                )
              }
              return this.showMsgDialog('warning', res.data.status_msg, 'false')
            })
            .catch((err) => {
              this.showMsgDialog('error', `${err}`, 'false')
              return (this.result = [])
            })
          this.loading = false
        }
        //  else {
        this.actionValue = 0
        // }
      })
    },
    resetDevice() {
      this.showMsgDialog(
        'question',
        `${this.selectedData.length} data registrasi perangkat karyawan akan di reset, Anda yakin ?`,
        true
      ).then((res) => {
        if (res.isConfirmed) {
          this.startResetDevice()
        } else {
          this.actionValue = 0
        }
      })
    },
    async startResetDevice() {
      this.loader = true
      const arrSuccess = []
      const arrFail = []
      let i = 0
      while (
        (await new Promise((resolve, reject) => {
          axios
            .post(`${this.hrsApi}employee/reset_device`, {
              employee_id: this.selectedData[0].id
            })
            .then((res) => {
              if (res.data.status_code === '00') {
                arrSuccess.push({
                  index: i,
                  msg: res.data.status_msg
                })
              } else {
                arrFail.push({
                  index: i,
                  msg: res.data.status_msg
                })
              }
              resolve(i++)
            })
            .catch((err) => {
              console.log(err)
              arrFail.push({
                index: i,
                msg: 'error'
              })
              reject(new Error(i++))
            })
        })) <
        this.selectedData.length - 1
      ) {
        console.log('cek ' + i)
      }

      this.loader = false
      this.actionValue = 0
      this.showMsgDialog(
        'success',
        `${arrSuccess.length} data berhasil di reset <br/> ${arrFail.length} data gagal di reset`,
        false
      )
      return this.getDataFromApi()
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.itemsPerPage = p
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },

    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString()
        return local
      }
    },
    async dropdownDepartment(p) {
      await axios
        .get(
          `${this.hrsApi}master/universal/department/dropdown?filter=[{"company_id":${p}}]`
        )
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.department = res.data.data)
          }
          return (this.dropdown.department = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.department = [])
        })
    },
    async dropdownSection() {
      this.dropdown.section = []
      await axios
        .get(
          `${this.hrsApi}master/universal/department/dropdown?parent_id=${this.paramAPI.department_id}`
        )
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.section = res.data.data)
          }
          return (this.dropdown.section = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.section = [])
        })
    },

    async dropdownEmployeeStatus() {
      axios
        .get(`${this.hrsApi}master/universal/employeestatus/dropdown`)
        .then((res) => {
          if (res.data.status_code === '00') {
            this.dropdown.employee_status = res.data.data

            return this.dropdown.employee_status.push({
              id: 10,
              name: 'Resign'
            })
          }
          return (this.dropdown.employee_status = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.employee_status = [])
        })
    },

    statusEmployee(val) {
      switch (val) {
        case -1:
          return 'Inactive'

        case 1:
          return 'Active'

        default:
          return '-'
      }
    },
    async dropdownLevel() {
      await axios
        .get(`${this.hrsApi}master/universal/employeelevel/dropdown`)
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.level = res.data.data)
          }
          return (this.dropdown.level = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.level = [])
        })
    },
    async getDropdownCompany() {
      const level = this.getUserProfile.level.find(
        ({ id }) => id === '1' || id === '39' || id === '42'
      )
      if (level !== undefined) {
        if (level.user_user_level !== null) {
          if (
            level.user_user_level.allowed_company === null ||
            level.user_user_level.allowed_company.length === 0
          ) {
            this.dropdownCompanyApi()
          } else {
            this.dropdown.company = level.user_user_level.allowed_company
            this.isDisableCompanyDropdown = false
          }
        } else {
          this.dropdownCompanyApi()
        }
      } else {
        this.dropdownCompanyApi()
      }
    },
    async dropdownCompanyApi() {
      if (Number(this.getUserProfile.employee.company.plant_id) !== 6) {
        this.isDisableCompanyDropdown = true
      } else {
        this.isDisableCompanyDropdown = false
      }
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          this.dropdown.company = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async exportData() {
      await axios
        .get(
          `${this.hrsApi}employee/list?keyword=${
            this.paramAPI.keyword
          }&company_id=${
            this.paramAPI.company_id !== null ? this.paramAPI.company_id : ''
          }&order_by=${this.paramAPI.sortBy}&order_type=${
            this.paramAPI.sortType
          }&offset=0&limit=10000`
        )
        .then((res) => {
          let selectedData = []
          const response = res.data.data
          if (response !== undefined) {
            for (let i = 0; i < response.length; i++) {
              selectedData.push({
                id: response[i].id,
                nik: response[i].nik,
                name: response[i].name,
                email: response[i].email,
                department_name: response[i].department_name,
                department_id: response[i].department_id,
                company_id: response[i].company_id,
                company_name: response[i].company_name,
                level_id: response[i].level.id,
                level_name: response[i].level.name,
                status: response[i].status,
                // join_date: response[i].join_date,
                // contract_date: response[i].contract_date,
                // expire_contract_date: response[i].contract_date,
                total_leave_advance: response[i].total_leave_advance,
                total_leave_n: response[i].total_leave_n,
                total_leave_n_1: response[i].total_leave_n_1
                // expire_leave_n_1: response[i].expire_leave_n_1
              })
            }
            this.exportNow(selectedData)
          } else {
            selectedData = []
          }
          return null
        })
        .catch((error) => {
          console.log(error)
          return null
        })
    },
    exportNow(selectedData) {
      const arrData = []
      for (let i = 0; i < selectedData.length; i++) {
        const param = {
          id: selectedData[i].id,
          nik: selectedData[i].nik,
          name: selectedData[i].name,
          email: selectedData[i].email,
          department_id: selectedData[i].department_id,
          department_name: selectedData[i].department_name,
          company_id: selectedData[i].company_id,
          company_name: selectedData[i].company_name,
          level_id: selectedData[i].level_id,
          level_name: selectedData[i].level_name,
          status: selectedData[i].status,
          total_leave_advance: selectedData[i].total_leave_advance,
          total_leave_n: selectedData[i].total_leave_n,
          total_leave_n_1: selectedData[i].total_leave_n_1
        }
        arrData.push(param)
      }
      this.downloadExcell(arrData)
    },
    downloadExcell(arrData) {
      const data = XLSX.utils.json_to_sheet(arrData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb, 'list_karyawan.xlsx')
    },
    importData() {
      // setTimeout(() => {
      //   document.getElementById('employeeImport').click()
      // }, 200)
    },

    async resetPassword() {
      this.showMsgDialog(
        'question',
        'Password akun karyawan tersebut akan direset, Anda yakin ?',
        true,
        false
      ).then(async (res) => {
        if (res.isConfirmed) {
          this.loading = true
          await axios
            .post(`${this.oAuthApi}user/forgot_password`, {
              email: this.selectedData[0].email
            })
            .then(async (res) => {
              if (res.data.status_code === '00') {
                return this.showMsgDialog(
                  'success',
                  res.data.status_msg,
                  'false'
                )
              }
              return this.showMsgDialog('warning', res.data.status_msg, 'false')
            })
            .catch((err) => {
              this.showMsgDialog('error', `${err}`, 'false')
              return (this.result = [])
            })
          this.loading = false
        }
        //  else {
        this.actionValue = 0
        // }
      })
    },
    async companyWatcher(p) {
      this.paramAPI.company_id = p

      this.dropdownDepartment(p)
      await this.getDataFromApi()
    },
    async departmentWatcher(p) {
      this.paramAPI.department_id = p
      await this.getDataFromApi()
    },
    async levelWatcher(p) {
      this.paramAPI.level_id = p
      await this.getDataFromApi()
    }
  }
}
</script>
<style lang="scss">
#employeeList {
  position: relative;
  .employee-list-div {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .employee-list-toolbar-1 {
      height: 60px;
      border-radius: 10px 10px 0 0;
      .employee-list-toolbar-div {
        width: 400px;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .employee-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #employeeList {
    .employee-list-div {
      .employee-list-toolbar-1 {
        display: none;
      }
      .employee-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
